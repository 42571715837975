import _ from 'lodash'

// WORK IN PROGRESS
/**
 * FLOW TERMINOLOGY
 * initial: start node
 *
 * check: Condition object, like a if
 *
 * on_true: It will execute when the check condition evaluates to true
 * on_false: It will execute when the check condition evaluates to false
 *
 * timeout: An object that will execute after a certain period if the end user does not perform an action
 *  - minute: Waiting time
 *  - to: The step to be redirected to
 *
 * status: dont_stop
 *
 * actions: Multiple action object
 *  - text: Message
 *  - to: The step to be redirected to
 *  - type: Action types
 *    - send: Message sending type
 *    - to: Redirect type
 *    - archive: Archive
 *    - assign_to_group: Group assignment
 *    - assign_to_agent: Agent assignment
 *    - setType: Conversation type assignment
 */

/**
 * WORKSPACE TERMINOLOGY
 * menuNode: Contains only the options
 * menuTextNode: Contains message and options
 * textNode: Contains only the message
 * reminderNode: Includes a reminder message and takes action
 * wrongAnswerNode: Includes an wrong answers response warning message and takes action
 *
 * condition: Contains action settings
 * utils: Includes utility nodes (reminder and wrongAnswer) settings
 * options: Includes option settings for menu and menuText nodes
 */

/**
 * OBJECT KEY TERMINOLOGY
 * textNode__, menuTextNode__, menuNode__: Specifies each node
 * info_: It is the key that contains the Information Message field defined in the action and utils nodes
 * select_: The menu nodes handle the control of each option in the menu
 * check_: The menu nodes handle the control of the number of options/selections in the menu
 * redirect_: In action operations, it enables the redirection to the next node after the message
 * timeout_: Specifies the node to redirect to for the timeout
 * again_: It is created based on the repeat count in the settings of the reminder and wrong answer
 * error_: It is the node to be used in case of any error condition
 */

/** UTILITIES FUNCTIONS */
const hasAnySelect = (nodeId, nodes, edges) => {
  const targets = _.map(_.filter(edges, ['source', nodeId]), 'target')

  if (targets.length) {
    const options = _.filter(nodes, ({ id }) => targets.includes(id))
    const any = _.find(options, ({ data }) => data.bot?.option?.value === 'any')

    if (any) {
      return {
        has: true,
        both: options.length > 1,
        nodeIds: _.filter(targets, (t) => t !== any.id),
        anyId: any.id
      }
    }
  }

  return { has: false, both: false }
}

const maxRepeatCount = (nodes) => {
  return (
    _.chain(nodes)
      .map((node) => {
        return _.get(node, 'data.utils.repeatCount', 1)
      })
      .max()
      .value() + 2
  )
}

// BUBBLES
const bubbles = (nodes) => {
  return _.chain(nodes)
    .filter((node) => node.type != 'group' && !node.parentNode)
    .keyBy('id')
    .mapValues((node) => {
      const { id, type, data } = node
      const { to, bot, options, condition } = data

      switch (type) {
        case 'textNode': {
          const actions = [{ text: bot.text, type: 'send' }]

          if (to) {
            actions.push({ to: to, type: 'to' })
          }

          if (
            condition?.action &&
            condition?.action?.informationMessage == null
          ) {
            const { type, agent, agentGroup } = condition.action

            actions.push({
              type: type,
              uuid: agent || agentGroup || undefined
            })
          } else if (condition?.action?.informationMessage) {
            actions.push({ to: `redirect_${id}`, type: 'to' })
          }

          return { actions }
        }

        case 'menuNode':
        case 'menuTextNode': {
          const displayOptions = _.chain(options)
            .map((opt, key) => `${key + 1} - ${opt.value}`)
            .join('\n')
            .value()

          return {
            actions: [
              {
                text: `${bot.text}\n\n${displayOptions}`,
                type: 'send'
              },
              {
                to: `check_${id}_1`,
                type: 'to'
              }
            ]
          }
        }
      }
    })
    .value()
}

const informationMessageBubble = (nodes) => {
  return _.chain(nodes)
    .filter((node) => {
      const { condition } = node.data

      return node.type != 'group' && condition?.action
    })
    .keyBy(({ id }) => `info_${id}`)
    .mapValues((node) => {
      const { condition } = node.data

      const text = condition?.action?.informationMessage || undefined

      const actions = [{ text, type: 'send' }]

      if (!_.isEmpty(condition)) {
        const { action } = condition

        const uuidKey = {
          setType: 'conversationType',
          assign_to_group: 'agentGroup',
          assign_to_agent: 'agent',
          archive: undefined
        }

        actions.push({
          type: action.type,
          uuid: action[uuidKey[action.type]]
        })
      }

      return { actions }
    })
    .value()
}

const utilsBubbles = (nodes, utilsType) => {
  const utilsNode = _.find(nodes, ['type', `${utilsType}Node`])
  const { repeatCount, message, action } = utilsNode.data.utils
  const rangeEnd = repeatCount + 1

  const _nodes = _.chain(nodes)
    .filter((node) => node.type == 'menuTextNode')
    .mapValues(({ id }) => {
      return _.chain(_.range(0, rangeEnd))
        .map((key) => {
          key = key + 1
          const errorMessageKey = `${utilsType}ErrorMessage_${id}_${key}`

          let errorActions = [
            {
              text: message,
              type: 'send'
            },
            { to: `check_${id}_${key + 1}`, type: 'to' }
          ]

          if (key == rangeEnd) {
            const {
              informationMessage,
              type = undefined,
              agent,
              agentGroup
            } = action

            errorActions = [
              {
                text: informationMessage,
                type: 'send'
              },
              {
                type: type,
                uuid: agent || agentGroup || undefined
              }
            ]
          }

          return {
            [`${utilsType}_${id}_${key}`]: {
              check: 'True',
              on_true: { to: errorMessageKey }
            },
            [errorMessageKey]: {
              actions: errorActions
            }
          }
        })

        .value()
    })
    .value()

  return _.chain(_nodes[0])
    .values()
    .reduce(function (result, value) {
      result = { ...result, ...value }

      return result
    }, {})
    .value()
}

// CHECK
const checkers = (nodes, edges) => {
  const _checkers = _.chain(nodes)
    .filter(['data.bot.hasCheck', true])
    .mapValues(({ data, id }) => {
      return _.chain(_.range(1, maxRepeatCount(nodes)))
        .map((key) => {
          const { options } = data
          const optionIds = _.chain(options)
            .map((_, key) => `'${key + 1}'`)
            .join(',')
            .value()

          let timeout = undefined
          const utilsNode = _.find(nodes, ['type', 'reminderNode'])

          if (utilsNode) {
            const { utils } = utilsNode.data

            timeout = {
              to: `reminder_${id}_${key}`,
              minute: utils.duration
            }
          }

          const any = hasAnySelect(id, nodes, edges)

          const on_true = { to: `select_${id}_1` }

          if (any.has && !any.both) on_true.to = any.anyId

          return {
            [`check_${id}_${key}`]: {
              check: `message.content in (${optionIds})`,
              status: 'dont_stop',
              on_true: on_true,
              timeout: timeout,
              on_false: { to: `wrongAnswer_${id}_${key}` }
            }
          }
        })
        .reduce((obj, param) => {
          const key = _.keys(param)[0]

          obj[key] = param[key]
          return obj
        }, {})
        .value()
    })
    .value()

  return _.chain(_checkers)
    .values()
    .reduce(function (result, value) {
      result = { ...result, ...value }

      return result
    }, {})
    .value()
}

// SELECT MENU
const selectors = (nodes, edges) => {
  const selects = _.chain(nodes)
    .filter(['data.bot.hasCheck', true])
    .mapValues(({ data, id }) => {
      const any = hasAnySelect(id, nodes, edges)

      return _.chain(data.options)
        .map((opt, key) => {
          key = key + 1
          const on_true = { to: `again_${id}` }

          const selectOpt = _.find(
            nodes,
            (node) => node.data.bot?.option?.id === opt.id
          )

          if (any.has && any.both) on_true.to = any.anyId
          if (selectOpt?.id) on_true.to = selectOpt.id

          return {
            [`select_${id}_${key}`]: {
              check: `message.content == '${key}'`,
              status: 'dont_stop',
              on_true: on_true,
              on_false:
                key !== data.options.length
                  ? {
                      to: `select_${id}_${key + 1}`
                    }
                  : undefined
            }
          }
        })
        .reduce((obj, param) => {
          const key = _.keys(param)[0]

          obj[key] = param[key]
          return obj
        }, {})
        .value()
    })
    .value()

  return _.chain(selects)
    .values()
    .reduce(function (result, value) {
      result = { ...result, ...value }

      return result
    }, {})
    .value()
}

const infoRedirects = (nodes) => {
  const redirects = _.chain(nodes)
    .filter((node) => node?.data?.condition?.action?.informationMessage)
    .mapValues(({ id }) => {
      return {
        [`redirect_${id}`]: {
          status: 'dont_stop',
          timeout: { to: `timeout_${id}`, minute: 0.025 }
        },
        [`timeout_${id}`]: {
          check: 'True',
          on_true: { to: `info_${id}` }
        }
      }
    })
    .value()

  return _.chain(redirects)
    .values()
    .reduce(function (result, value) {
      result = { ...result, ...value }

      return result
    }, {})
    .value()
}

export const dataGenerator = (schema) => {
  const { nodes, edges } = schema

  if (nodes.length === 0) return {}

  const firstBubble = _.find(nodes, ['data.isFirst', true])

  // GENERATE INIT
  const init = {
    check: 'True',
    initial: true,
    on_true: { to: firstBubble.id }
  }

  const data = {
    init,
    ...bubbles(nodes),
    ...utilsBubbles(nodes, 'reminder'),
    ...utilsBubbles(nodes, 'wrongAnswer'),
    ...checkers(nodes, edges),
    ...selectors(nodes, edges),
    ...informationMessageBubble(nodes),
    ...infoRedirects(nodes)
  }

  return data
}
