import React from 'react'
import {
  MdOutlineChromeReaderMode,
  MdOutlineEarbuds,
  MdOutlineForum,
  MdOutlineMenuOpen,
  MdOutlineNotificationsActive,
  MdOutlineRule
} from 'react-icons/md'

import {
  ActionItem,
  ActionItemList,
  ActionMenuWrapper,
  ActionSection,
  ActionTitle
} from './ActionMenu.styled'

export const ActionMenu = () => {
  const onDragStart = (event, nodeOptions) => {
    event.dataTransfer.setData(
      'application/reactflow',
      JSON.stringify(nodeOptions)
    )
    event.dataTransfer.effectAllowed = 'move'
  }

  /* const onInputDragStart = (event, type) => {
    event.dataTransfer.setData(
      'text/plain',
      JSON.stringify({
        dataType: 'input',
        data: { type }
      })
    )
  } */

  const onConditionsDragStart = (event, type) => {
    event.dataTransfer.setData(
      'text/plain',
      JSON.stringify({
        dataType: 'condition',
        data: { type }
      })
    )
  }

  return (
    <ActionMenuWrapper>
      <ActionSection>
        <ActionTitle>Bubbles</ActionTitle>
        <ActionItemList>
          <ActionItem
            onDragStart={(event) => {
              onDragStart(event, {
                type: 'textNode',
                data: {
                  label: 'Your Text Bubble',
                  bot: { text: '' },
                  condition: {}
                }
              })
            }}
          >
            <MdOutlineForum />
            Text
          </ActionItem>
          <ActionItem
            onDragStart={(event) => {
              onDragStart(event, {
                type: 'menuNode',
                data: {
                  label: 'Your Menu Bubble',
                  bot: { text: '', hasCheck: true },
                  options: [],
                  condition: {}
                }
              })
            }}
          >
            <MdOutlineMenuOpen />
            Menu
          </ActionItem>
          <ActionItem
            onDragStart={(event) => {
              onDragStart(event, {
                type: 'menuTextNode',
                data: {
                  label: 'Your Menu + Text Bubble',
                  bot: { text: '', hasCheck: true },
                  options: [],
                  condition: {}
                }
              })
            }}
          >
            <MdOutlineChromeReaderMode />
            Menu + Text
          </ActionItem>
        </ActionItemList>
      </ActionSection>

      {/* <ActionSection>
        <ActionTitle>Inputs</ActionTitle>
        <ActionItemList>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <ActionItem
                onDragStart={(event) => {
                  onInputDragStart(event, 'text')
                }}
              >
                <MdOutlineTextFields />
                Text
              </ActionItem>
            </Col>
            <Col span={12}>
              <ActionItem
                onDragStart={(event) => {
                  onInputDragStart(event, 'number')
                }}
              >
                <MdOutlineTag />
                Number
              </ActionItem>
            </Col>
            <Col span={12}>
              <ActionItem
                onDragStart={(event) => {
                  onInputDragStart(event, 'email')
                }}
              >
                <MdOutlineAlternateEmail />
                Email
              </ActionItem>
            </Col>
            <Col span={12}>
              <ActionItem
                onDragStart={(event) => {
                  onInputDragStart(event, 'phone')
                }}
              >
                <MdOutlinePhone />
                Phone
              </ActionItem>
            </Col>
          </Row>
        </ActionItemList>
      </ActionSection> */}

      <ActionSection>
        <ActionTitle>Utils</ActionTitle>
        <ActionItemList>
          <ActionItem
            onDragStart={(event) => {
              onDragStart(event, {
                mainType: 'utilsNode',
                type: 'reminderNode',
                data: {
                  label: 'Reminder Bubble'
                }
              })
            }}
          >
            <MdOutlineNotificationsActive />
            Reminder
          </ActionItem>
          <ActionItem
            onDragStart={(event) => {
              onDragStart(event, {
                mainType: 'utilsNode',
                type: 'wrongAnswerNode',
                data: {
                  label: 'Wrong Answer Bubble'
                }
              })
            }}
          >
            <MdOutlineRule />
            Wrong Answer
          </ActionItem>
        </ActionItemList>
      </ActionSection>

      <ActionSection>
        <ActionTitle>Conditions</ActionTitle>
        <ActionItemList>
          <ActionItem
            onDragStart={(event) => {
              onConditionsDragStart(event, 'action')
            }}
          >
            <MdOutlineEarbuds />
            Action
          </ActionItem>
        </ActionItemList>
      </ActionSection>
    </ActionMenuWrapper>
  )
}
