import React, { useCallback, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Controls, Background, ReactFlowProvider } from 'reactflow'

import { Loading } from 'foundation'
import { ConnectionLine, ActionMenu } from 'components'
import { edgeTypes, getRandomPosition, nodeTypes } from 'utils'
import {
  handleAddNode,
  handleEdgesChange,
  handleNodesChange,
  handleConnect,
  handleInstance
} from 'store/actions'
import { LoaderWrapper, StyledReactFlow, Wrapper } from './Flow.styled'

import 'reactflow/dist/style.css'

export const Flow = () => {
  const reactFlowWrapper = useRef(null)
  const dispatch = useDispatch()

  const { isFetched } = useSelector((state) => state.flow)
  const { instance, edges, nodes, viewport } = useSelector(
    (state) => state.elements
  )

  const onInit = useCallback((reactFlowInstance) => {
    dispatch(handleInstance(reactFlowInstance))
  }, [])

  const onConnect = useCallback((connection) => {
    dispatch(handleConnect(connection))
  }, [])

  const onNodesChange = useCallback((nodeChanges) => {
    dispatch(handleNodesChange(nodeChanges))
  }, [])

  const onEdgesChange = useCallback((edgeChanges) => {
    dispatch(handleEdgesChange(edgeChanges))
  }, [])

  const onDragOver = useCallback((event) => {
    event.preventDefault()
    event.dataTransfer.dropEffect = 'move'
  }, [])

  const onDrop = useCallback(
    (event) => {
      event.preventDefault()

      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect()
      const nodeOptions = event.dataTransfer.getData('application/reactflow')

      if (!nodeOptions) return
      const { type, data, mainType } = JSON.parse(nodeOptions)

      if (typeof type === 'undefined' || !type) {
        return
      }

      const position = instance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top
      })

      let node = {
        id: `${type}__${new Date().getTime().toString()}`,
        type,
        position,
        data: {
          ...data,
          isFirst: !nodes.length
        }
      }

      if (mainType == 'utilsNode') {
        const currentParent = nodes.find((n) => n.type == 'group')
        const reminderNode = nodes.find((n) => n.type == 'reminderNode')
        const wrongAnswerNode = nodes.find((n) => n.type == 'wrongAnswerNode')

        if (
          (type == 'reminderNode' && reminderNode) ||
          (type == 'wrongAnswerNode' && wrongAnswerNode)
        ) {
          return
        }

        let parentId = `${mainType}__${new Date().getTime().toString()}`

        if (currentParent) {
          parentId = currentParent?.id
        } else {
          const parent = {
            id: parentId,
            position,
            type: 'group',
            connectable: false,
            deletable: true,
            data: { label: 'Utils Area' },
            style: {
              backgroundColor: 'rgba(56, 96, 238, 0.2)',
              minWidth: 750,
              minHeight: 750,
              borderRadius: 8,
              border: '2px dashed #3760ee'
            }
          }

          dispatch(handleAddNode(parent))
        }

        node = {
          ...node,
          parentNode: parentId,
          expandParent: false, // autosize parent node
          extent: 'parent',
          position: getRandomPosition()
        }
      }

      dispatch(handleAddNode(node))
    },
    [instance, nodes]
  )

  return (
    <Wrapper>
      <ReactFlowProvider>
        {isFetched ? (
          <div className='reactflow-wrapper w-100 h-100' ref={reactFlowWrapper}>
            <StyledReactFlow
              nodes={nodes}
              edges={edges}
              nodeTypes={nodeTypes}
              edgeTypes={edgeTypes}
              defaultViewport={viewport}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              onConnect={onConnect}
              onDrop={onDrop}
              onDragOver={onDragOver}
              onInit={onInit}
              disableKeyboardA11y
              {...ConnectionLine}
            >
              <Controls />
              <Background gap={12} />
            </StyledReactFlow>
          </div>
        ) : (
          <LoaderWrapper>
            <Loading size='32px' />
          </LoaderWrapper>
        )}

        <ActionMenu />
      </ReactFlowProvider>
    </Wrapper>
  )
}
