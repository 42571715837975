import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import _ from 'lodash'

import { TbDragDrop2 } from 'react-icons/tb'
import { Wrapper, DropAreaWrapper } from '../Bubbles.styled'

import { Condition } from 'components/Conditions'

export const DroppableWrapper = ({ id, children, isDroppable = true }) => {
  const node = useSelector((state) => _.find(state.elements.nodes, ['id', id]))

  const [isDragOver, setIsDragOver] = useState(false)
  const [conditionElement, setConditionElement] = useState(null)

  useEffect(() => {
    if (node?.data) {
      setConditionElement(node.data?.condition)
    }
  }, [node?.data?.condition])

  const handleDragOver = (e) => {
    if (!isDroppable) {
      e.stopPropagation()

      return
    }

    e.preventDefault()

    setIsDragOver(e.dataTransfer.effectAllowed == 'all')
  }

  const handleDrop = (e) => {
    if (!isDroppable) {
      e.stopPropagation()

      return
    }

    e.preventDefault()

    const transferedData = e.dataTransfer.getData('text/plain')

    if (transferedData) {
      const { dataType, data } = JSON.parse(transferedData)

      if (dataType) {
        if (dataType === 'condition') {
          setConditionElement(data)
        }
      }
    }

    setIsDragOver(false)
    return null
  }

  return (
    <Wrapper
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragLeave={() => setIsDragOver(false)}
    >
      {children || null}

      {isDragOver && (
        <DropAreaWrapper>
          <TbDragDrop2 />
        </DropAreaWrapper>
      )}

      {!_.isEmpty(conditionElement) && (
        <Condition id={id} conditionType={conditionElement.type} />
      )}
    </Wrapper>
  )
}

DroppableWrapper.Option = Wrapper.Option

DroppableWrapper.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  isDroppable: PropTypes.bool
}
