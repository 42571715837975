import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import _ from 'lodash'
import { MdAdd, MdOutlineDelete } from 'react-icons/md'

import {
  NewOptionButton,
  OptionButton,
  OptionsWrapper,
  OptionWrapper,
  RoundedButton
} from '../Bubbles.styled'
import { getDataByName } from 'utils'
import { handleUpdateData } from 'store/actions'
import { Dropdown } from 'foundation'

const generateId = () => {
  return `option_${new Date().getTime()}`
}

export const Options = ({ id }) => {
  const dispatch = useDispatch()
  const [isTouched, setIsTouched] = useState(false)
  const [options, setOptions] = useState([])

  useEffect(() => {
    const opts = getDataByName(id, 'options')

    if (opts?.length) {
      setOptions(opts)
    } else {
      setOptions([
        {
          id: generateId(),
          value: ''
        },
        {
          id: generateId() + 1,
          value: ''
        }
      ])
    }
  }, [])

  useEffect(() => {
    if (isTouched) {
      dispatch(handleUpdateData(id, options, 'options'))
    }
  }, [isTouched, options])

  const onChange = (event) => {
    const { value, name } = event.target

    const updated = _.map(options, (o) => {
      if (o.id === name) {
        return { ...o, value }
      }

      return o
    })

    setIsTouched(true)
    setOptions(updated)
  }

  const addOption = () => {
    setIsTouched(true)

    setOptions((opts) =>
      opts.concat({
        id: generateId(),
        value: ''
      })
    )
  }

  const deleteOption = (id) => {
    setIsTouched(true)

    setOptions((opts) => opts.filter((opt) => opt.id !== id))
  }

  const renderOptions = () => {
    return options.map((option, key) => {
      return (
        <Dropdown
          key={option.id}
          trigger={['contextMenu']}
          menu={{
            items: [
              {
                key: 'remove',
                label: 'Remove Option',
                icon: <MdOutlineDelete size={20} />,
                danger: true,
                onClick: () => deleteOption(option.id)
              }
            ]
          }}
        >
          <OptionWrapper>
            <OptionButton
              name={option.id}
              value={option.value}
              placeholder={`Button ${key + 1}`}
              onChange={onChange}
            />
          </OptionWrapper>
        </Dropdown>
      )
    })
  }

  return (
    <OptionsWrapper id={id}>
      {renderOptions()}
      <NewOptionButton>
        <RoundedButton onClick={addOption}>
          <MdAdd />
        </RoundedButton>
      </NewOptionButton>
    </OptionsWrapper>
  )
}

Options.propTypes = {
  id: PropTypes.string
}
