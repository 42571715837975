import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Divider, Row, Col } from 'antd'
import PropTypes from 'prop-types'
import _ from 'lodash'

import {
  MdClear,
  MdOutlineAddCircleOutline,
  MdOutlineDelete,
  MdOutlineEarbuds
} from 'react-icons/md'

import { Select, Dropdown } from 'foundation'
import {
  fetchAgentGroups,
  fetchAgents,
  fetchConversationTypes,
  handleUpdateCondition
} from 'store/actions'
import { getConditionDataById } from 'utils'

import {
  actionInitialData,
  actionOptions,
  conditionInitialData,
  conditionOptions,
  icon
} from './constants'
import {
  ConditionButtonWrapper,
  Wrapper,
  Head,
  ConditionIcon,
  ConditionTitle,
  CloseIcon,
  Section,
  SectionTitle,
  ConditionButton,
  StyledConditionTextInput
} from './Condition.styled'
import { StyledTextInput } from 'components/Bubbles/Bubbles.styled'

const { Option } = Select

export const Condition = ({
  id,
  conditionType,
  optionButton,
  onOptionButtonChange
}) => {
  const showButtonRef = useRef(null)
  const dispatch = useDispatch()
  const { isFetched, isFetching, agents, agentGroups, conversationTypes } =
    useSelector((state) => state.account)

  const [showDetail, setShowDetail] = useState(false)
  const [data, setData] = useState(conditionInitialData(conditionType))

  const conditionLabel = _.find(conditionOptions, [
    'value',
    conditionType
  ]).label

  useEffect(() => {
    if (id) {
      const conditionData = getConditionDataById(id)

      if (!_.isEmpty(conditionData)) {
        setData(Object.assign(conditionData, {}))
        return
      }
      setData(conditionInitialData(conditionType))
      return
    }

    if (optionButton) {
      setShowDetail(true)
      if (!_.isEmpty(optionButton.condition)) {
        setData(Object.assign(optionButton.condition, {}))
        return
      }
      setData(actionInitialData)
      return
    }
  }, [id, optionButton, showDetail])

  useEffect(() => {
    // Agents
    if (data.action.type === 'assign_to_agent' && !isFetched.agents) {
      dispatch(fetchAgents())
    }

    // Agent Groups
    if (data.action.type === 'assign_to_group' && !isFetched.agentGroups) {
      dispatch(fetchAgentGroups())
    }

    // Conversation Types
    if (data.action.type === 'setType' && !isFetched.conversationTypes) {
      dispatch(fetchConversationTypes())
    }
  }, [data.action.type])

  const conversationTypesOptions = () => {
    return _.filter(conversationTypes, ['is_active', true]).map((type) => ({
      label: type.name,
      value: type.uuid
    }))
  }

  const agentGroupsOptions = () => {
    return _.map(agentGroups, (type) => ({
      label: type.name,
      value: type.uuid
    }))
  }

  const onActionChange = (name, value) => {
    setData((prev) => ({
      ...prev,
      action: {
        ...prev.action,
        [name]: value
      }
    }))
  }

  const onInputChange = (event) => {
    let { name, value } = event.target

    value = parseInt(value)
    if (!value || value < 0) value = 0

    setData((prev) => ({
      ...prev,
      [name]: value
    }))
  }

  const onActionInputChange = (event) => {
    let { value } = event.target

    setData((prev) => ({
      ...prev,
      action: {
        ...prev.action,
        informationMessage: value
      }
    }))
  }

  const onSave = () => {
    if (id) {
      dispatch(handleUpdateCondition(id, data))
      setShowDetail(false)
      return
    }

    if (optionButton) {
      onOptionButtonChange(optionButton.id, data)
    }
  }

  const onClose = () => {
    if (id) {
      setShowDetail(false)
      return
    }

    if (optionButton) {
      onOptionButtonChange(optionButton.id)
    }
  }

  const renderDetail = () => {
    let offsetTop = 1
    let offsetLeft = 1

    if (id) {
      offsetTop = showButtonRef.current.offsetTop
      offsetLeft = showButtonRef.current.offsetLeft
    }

    return (
      <Wrapper top={offsetTop} left={offsetLeft}>
        <Head>
          <ConditionIcon>
            <MdOutlineEarbuds />
          </ConditionIcon>
          <ConditionTitle>{conditionLabel} Details</ConditionTitle>
          <CloseIcon>
            <MdClear onClick={onClose} />
          </CloseIcon>
        </Head>

        {!optionButton && (
          <>
            <Divider />
            <Section>
              <Row gutter={[12, 12]}>
                <Col span={24}>
                  <SectionTitle>Duration</SectionTitle>
                  <StyledConditionTextInput
                    value={data.duration}
                    type='number'
                    name='duration'
                    className='nodrag'
                    onChange={onInputChange}
                    suffix='min.'
                    min={0}
                  />
                </Col>
              </Row>
            </Section>
          </>
        )}

        <Divider />
        <Section>
          <Row gutter={[16, 12]}>
            <Col span={24}>
              <SectionTitle>Action</SectionTitle>
              <Select
                placeholder='Select Action'
                className='nodrag'
                options={actionOptions}
                value={data.action.type}
                onChange={(value) => onActionChange('type', value)}
              />
            </Col>

            {data.action.type === 'setType' && (
              <Col span={24}>
                <SectionTitle>Type</SectionTitle>
                <Select
                  showSearch
                  placeholder='Select Type'
                  options={conversationTypesOptions()}
                  value={data.action.conversationType}
                  loading={isFetching.conversationTypes}
                  onChange={(value) =>
                    onActionChange('conversationType', value)
                  }
                />
              </Col>
            )}

            {data.action.type === 'assign_to_agent' && (
              <Col span={24}>
                <SectionTitle>Agent</SectionTitle>
                <Select
                  showSearch
                  placeholder='Select Agent'
                  loading={isFetching.agents}
                  value={data.action.agent}
                  onChange={(value) => onActionChange('agent', value)}
                  filterOption={(input, option) => {
                    let value = option.children

                    value = _.isArray(value)
                      ? _.filter(value, _.isString).join('')
                      : value

                    return (
                      value
                        .toLocaleLowerCase()
                        .indexOf(input.toLocaleLowerCase()) >= 0
                    )
                  }}
                >
                  {_.filter(agents, ['user.is_active', true]).map((agent) => (
                    <Option key={agent.uuid}>
                      {agent.user.first_name} {agent.user.last_name}
                    </Option>
                  ))}
                </Select>
              </Col>
            )}

            {data.action.type === 'assign_to_group' && (
              <Col span={24}>
                <SectionTitle>Agent Group</SectionTitle>
                <Select
                  placeholder='Select Group'
                  options={agentGroupsOptions()}
                  loading={isFetching.agentGroups}
                  value={data.action.agentGroup}
                  onChange={(value) => onActionChange('agentGroup', value)}
                />
              </Col>
            )}

            <Col span={24}>
              <label style={{ marginBottom: '4px', display: 'block' }}>
                Information Message{' '}
                <span style={{ color: 'rgb(0 0 0 / 45%)' }}>(Optional)</span>
              </label>
              <StyledTextInput
                value={data.action.informationMessage}
                onChange={onActionInputChange}
                placeholder='Your session has been terminated.'
              />
            </Col>
          </Row>
        </Section>

        <Section>
          <ConditionButton
            icon={<MdOutlineAddCircleOutline />}
            onClick={onSave}
          >
            Set {conditionLabel}
          </ConditionButton>
        </Section>
      </Wrapper>
    )
  }

  return (
    <Dropdown
      trigger={['contextMenu']}
      menu={{
        items: [
          {
            key: 'remove',
            label: 'Remove Condition',
            icon: <MdOutlineDelete size={20} />,
            danger: true,
            onClick: () => dispatch(handleUpdateCondition(id, {}))
          }
        ]
      }}
    >
      <div>
        {conditionType && (
          <ConditionButtonWrapper
            ref={showButtonRef}
            onClick={() => setShowDetail(!showDetail)}
          >
            {icon[conditionType]}
            <span>{conditionLabel}</span>
          </ConditionButtonWrapper>
        )}

        {showDetail && renderDetail()}
      </div>
    </Dropdown>
  )
}

Condition.propTypes = {
  id: PropTypes.string,
  conditionType: PropTypes.string,
  optionButton: PropTypes.object,
  onOptionButtonChange: PropTypes.func
}

Condition.displayName = 'Condition'
