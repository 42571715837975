import {
  TextBubble,
  MenuTextBubble,
  MenuBubble,
  UtilsGroupBubble,
  ReminderBubble,
  WrongAnswerBubble
} from 'components/Bubbles'
import { CustomEdge } from 'components/Edges'

export const nodeTypes = {
  textNode: TextBubble,
  menuNode: MenuBubble,
  menuTextNode: MenuTextBubble,
  group: UtilsGroupBubble,
  reminderNode: ReminderBubble,
  wrongAnswerNode: WrongAnswerBubble
}

export const edgeTypes = {
  customEdge: CustomEdge
}
