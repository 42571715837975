import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import {
  MdOutlineDelete,
  MdOutlineNotificationsActive,
  MdOutlineRule
} from 'react-icons/md'

import { BubbleDeleteIcon, BubbleIcon, Title } from '../Bubbles.styled'
import { handleDeleteNode } from 'store/actions'

const icon = {
  reminder: <MdOutlineNotificationsActive />,
  wrongAnswer: <MdOutlineRule />
}

export const UtilsHead = ({ id, data, type = 'reminder' }) => {
  const dispatch = useDispatch()

  const onDelete = () => {
    dispatch(handleDeleteNode(id))
  }

  return (
    <Title>
      <BubbleIcon>{icon[type]}</BubbleIcon>
      <div className='utils-label'>{data.label}</div>

      <BubbleDeleteIcon onClick={onDelete}>
        <MdOutlineDelete />
      </BubbleDeleteIcon>
    </Title>
  )
}

UtilsHead.propTypes = {
  id: PropTypes.string,
  data: PropTypes.object,
  type: PropTypes.string
}
