import React from 'react'
import { MdOutlineEarbuds } from 'react-icons/md'

export const conditionOptions = [
  {
    value: 'action',
    label: 'Action'
  }
]

export const actionOptions = [
  {
    value: 'setType',
    label: 'Set Conversation Type'
  },
  {
    value: 'assign_to_group',
    label: 'Assign to Agent Group'
  },
  {
    value: 'assign_to_agent',
    label: 'Assign to Agent'
  },
  {
    value: 'archive',
    label: 'Archive Conversation'
  }
]

export const icon = {
  action: <MdOutlineEarbuds />
}

export const conditionInitialData = (type = 'action') => ({
  type,
  duration: 0,
  action: {
    type: null,
    agent: null,
    agentGroup: null,
    informationMessage: null
  }
})

export const actionInitialData = {
  action: {
    type: null,
    conversationType: null,
    agent: null,
    agentGroup: null,
    informationMessage: null
  }
}
