import React, { memo, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'

import {
  actionOptions,
  getUtilsDataById,
  utilsSettingsInitialData
} from 'utils'
import { DroppableWrapper, UtilsHead } from './common'
import { Select } from 'foundation'
import {
  StyledDivider,
  MiniTextInput,
  StyledForm,
  StyledFormItem,
  MiniTextArea
} from './UtilsGroupBubble.styled'
import {
  fetchAgentGroups,
  fetchAgents,
  fetchConversationTypes,
  handleUpdateUtils
} from 'store/actions'

const { Option } = Select

export const ReminderBubble = memo(({ id, data: flowData }) => {
  const dispatch = useDispatch()
  const { isFetched, isFetching, agents, agentGroups, conversationTypes } =
    useSelector((state) => state.account)
  const [data, setData] = useState(utilsSettingsInitialData('reminder'))

  useEffect(() => {
    if (id) {
      const utilsData = getUtilsDataById(id)

      if (!_.isEmpty(utilsData)) {
        setData(Object.assign(utilsData, {}))
        return
      }
      setData(utilsSettingsInitialData('reminder'))
      return
    }
  }, [id])

  useEffect(() => {
    // Agents
    if (data.action.type === 'assign_to_agent' && !isFetched.agents) {
      dispatch(fetchAgents())
    }

    // Agent Groups
    if (data.action.type === 'assign_to_group' && !isFetched.agentGroups) {
      dispatch(fetchAgentGroups())
    }

    // Conversation Types
    if (data.action.type === 'setType' && !isFetched.conversationTypes) {
      dispatch(fetchConversationTypes())
    }
  }, [data.action.type])

  useEffect(() => {
    dispatch(handleUpdateUtils(id, data))
  }, [data])

  const conversationTypesOptions = () => {
    return _.filter(conversationTypes, ['is_active', true]).map((type) => ({
      label: type.name,
      value: type.uuid
    }))
  }

  const agentGroupsOptions = () => {
    return _.map(agentGroups, (type) => ({
      label: type.name,
      value: type.uuid
    }))
  }

  const onInputChange = (event) => {
    let { name, value } = event.target

    if (name != 'message') {
      value = parseInt(value)

      if (!value || value < 1) {
        value = 1
      }
    }

    setData((prev) => ({
      ...prev,
      [name]: value
    }))
  }

  const onActionChange = (name, value) => {
    setData((prev) => ({
      ...prev,
      action: {
        ...prev.action,
        [name]: value
      }
    }))
  }

  const onActionInputChange = (event) => {
    let { value } = event.target

    setData((prev) => ({
      ...prev,
      action: {
        ...prev.action,
        informationMessage: value
      }
    }))
  }

  return (
    <DroppableWrapper id={id} isDroppable={false}>
      <UtilsHead id={id} data={flowData} />
      <MiniTextArea
        id={id}
        placeholder='Enter reminder message...'
        name='message'
        value={data.message}
        onChange={onInputChange}
      />

      <StyledDivider plain orientation='left'>
        Settings
      </StyledDivider>

      <StyledForm layout='vertical'>
        <Row gutter={[12, 12]}>
          <Col span={12}>
            <StyledFormItem
              label='Repeat Count'
              tooltip='Repeat count determines how many times this reminder message will be triggered. If the user does not respond to the message, it will send reminder messages up to the repeat count until a response is received.'
            >
              <MiniTextInput
                value={data.repeatCount}
                min={1}
                type='number'
                name='repeatCount'
                className='nodrag'
                onChange={onInputChange}
              />
            </StyledFormItem>
          </Col>
          <Col span={12}>
            <StyledFormItem
              label='Duration'
              tooltip={
                "Duration determines how long after the user's non-response the reminder message will be sent."
              }
            >
              <MiniTextInput
                value={data.duration}
                min={1}
                type='number'
                name='duration'
                className='nodrag'
                suffix='min.'
                onChange={onInputChange}
              />
            </StyledFormItem>
          </Col>
        </Row>
      </StyledForm>

      <StyledDivider plain orientation='left'>
        Actions
      </StyledDivider>

      <StyledForm layout='vertical' requiredMark='optional'>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <StyledFormItem
              label='Action'
              tooltip='Action is the field where you choose what to do after the reminder process is completed.'
              required
            >
              <Select
                size='small'
                placeholder='Select Action'
                className='nodrag'
                options={actionOptions}
                value={data.action.type}
                onChange={(value) => onActionChange('type', value)}
              />
            </StyledFormItem>
          </Col>

          {data.action.type === 'setType' && (
            <Col span={24}>
              <StyledFormItem label='Type'>
                <Select
                  showSearch
                  size='small'
                  placeholder='Select Type'
                  options={conversationTypesOptions()}
                  value={data.action.conversationType}
                  loading={isFetching.conversationTypes}
                  onChange={(value) =>
                    onActionChange('conversationType', value)
                  }
                />
              </StyledFormItem>
            </Col>
          )}

          {data.action.type === 'assign_to_agent' && (
            <Col span={24}>
              <StyledFormItem label='Agent'>
                <Select
                  showSearch
                  size='small'
                  placeholder='Select Agent'
                  loading={isFetching.agents}
                  value={data.action.agent}
                  onChange={(value) => onActionChange('agent', value)}
                  filterOption={(input, option) => {
                    let value = option.children

                    value = _.isArray(value)
                      ? _.filter(value, _.isString).join('')
                      : value

                    return (
                      value
                        .toLocaleLowerCase()
                        .indexOf(input.toLocaleLowerCase()) >= 0
                    )
                  }}
                >
                  {_.filter(agents, ['user.is_active', true]).map((agent) => (
                    <Option key={agent.uuid}>
                      {agent.user.first_name} {agent.user.last_name}
                    </Option>
                  ))}
                </Select>
              </StyledFormItem>
            </Col>
          )}

          {data.action.type === 'assign_to_group' && (
            <Col span={24}>
              <StyledFormItem label='Agent Group'>
                <Select
                  size='small'
                  placeholder='Select Group'
                  options={agentGroupsOptions()}
                  loading={isFetching.agentGroups}
                  value={data.action.agentGroup}
                  onChange={(value) => onActionChange('agentGroup', value)}
                />
              </StyledFormItem>
            </Col>
          )}

          <Col span={24}>
            <StyledFormItem
              label='Information Message'
              tooltip='If the user does not respond after the specified repeat count and duration, the action taken in the conversation is performed. If you want to send an informational message to the user after this action, you can enter it here. (This field is optional and can be left blank.)'
            >
              <MiniTextArea
                name='informationMessage'
                value={data.action.informationMessage}
                onChange={onActionInputChange}
                placeholder='Your session has been terminated.'
              />
            </StyledFormItem>
          </Col>
        </Row>
      </StyledForm>
    </DroppableWrapper>
  )
})

ReminderBubble.propTypes = {
  id: PropTypes.string,
  data: PropTypes.object,
  isConnectable: PropTypes.bool
}

ReminderBubble.displayName = 'ReminderBubble'
