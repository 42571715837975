import styled from 'styled-components'

import playPreviewIcon from 'assets/images/play-preview.svg'

export const Wrapper = styled.div``

export const PreviewButton = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  height: 48px;
  width: 48px;
  margin-top: 10px;
  margin-right: 32px;
  margin-bottom: 32px;
  border-radius: 50%;
  box-shadow: 0 5px 9px 0 rgba(10, 13, 54, 0.13);
  clear: both;
  float: right;
  background-color: #3860ee;
  cursor: pointer;
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;

  div {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    -webkit-transition: opacity 80ms linear, -webkit-transform 0.16s linear;
    transition: opacity 80ms linear, -webkit-transform 0.16s linear;
    transition: transform 0.16s linear, opacity 80ms linear;
    transition: transform 0.16s linear, opacity 80ms linear,
      -webkit-transform 0.16s linear;
    background-position: 50%;
    background-repeat: no-repeat;

    ${(p) => {
      return !p.show
        ? `
        background-image: url(${playPreviewIcon});
        background-size: 24px;
        background-repeat: no-repeat;
        background-position: center;
        opacity: 1;
        -webkit-transform: rotate(0) scale(1);
        transform: rotate(0) scale(1);
    `
        : `
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoCiAgICAgICAgZD0iTTE5IDYuNDFMMTcuNTkgNUwxMiAxMC41OUw2LjQxIDVMNSA2LjQxTDEwLjU5IDEyTDUgMTcuNTlMNi40MSAxOUwxMiAxMy40MUwxNy41OSAxOUwxOSAxNy41OUwxMy40MSAxMkwxOSA2LjQxWiIKICAgICAgICBmaWxsPSIjRkZGRkZGIiAvPgo8L3N2Zz4K);
        background-size: 24px;
        background-repeat: no-repeat;
        -webkit-transform: rotate(-30deg);
        transform: rotate(-30deg);
        opacity: 1;
        -webkit-transform: rotate(0);
        transform: rotate(0);
        background-color: #0a0d36;
        border-radius: 50%;
    `
    }}
  }
`

export const PreviewFrame = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 32px;
  bottom: 94px;
  width: 100%;
  max-width: 400px;
  height: 1100px;
  max-height: calc(100vh - 120px);
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0 5px 13px 0 rgb(0 0 0 / 15%);
  border-radius: 12px;
  transition: opacity 0.25s, height 0.5s;
  background-color: #fff;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
`
