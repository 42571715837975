/* UTILS CONSTANTS */

export const actionOptions = [
  {
    value: 'setType',
    label: 'Set Conversation Type'
  },
  {
    value: 'assign_to_group',
    label: 'Assign to Agent Group'
  },
  {
    value: 'assign_to_agent',
    label: 'Assign to Agent'
  },
  {
    value: 'archive',
    label: 'Archive Conversation'
  }
]

export const utilsSettingsInitialData = (type = 'repeat') => ({
  type,
  repeatCount: 1,
  duration: 1,
  message: '',
  action: {
    type: null,
    agent: null,
    agentGroup: null,
    informationMessage: null
  }
})

export const actionInitialData = {
  action: {
    type: null,
    conversationType: null,
    agent: null,
    agentGroup: null,
    informationMessage: null
  }
}
