import React, { memo } from 'react'
import PropTypes from 'prop-types'

import { UtilsTitle } from './UtilsGroupBubble.styled'

export const UtilsGroupBubble = memo(({ data }) => {
  return <UtilsTitle>{data.label}</UtilsTitle>
})

UtilsGroupBubble.propTypes = {
  data: PropTypes.object
}

UtilsGroupBubble.displayName = 'UtilsGroupBubble'
