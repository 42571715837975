import { store } from 'store'
import _ from 'lodash'

export const getUtilsDataById = (id) => {
  const state = store.getState()
  const nodes = state.elements.nodes

  const getNode = () => {
    const selected = _.find(nodes, ['id', id])

    return selected
  }

  return getNode()?.data?.utils
}
