import styled from 'styled-components'
import { Divider, Form } from 'antd'

import { Input } from 'foundation'

export const UtilsTitle = styled.div`
  width: 100%;
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  padding: 16px;
  color: var(--secondary);
`

export const StyledDivider = styled(Divider)`
  margin-top: 20px !important;
  margin-bottom: 12px !important;

  &::before {
    display: none;
  }

  .ant-divider-inner-text {
    padding: 0 8px 0 1px;
    text-transform: uppercase;
    font-size: 10px;
    color: var(--text-dark-3);
  }
`

export const StyledForm = styled(Form)``

export const StyledFormItem = styled(Form.Item)`
  margin-bottom: 0;

  .ant-form-item-label {
    padding: 0;

    label {
      display: flex;
      align-items: center;
      margin-bottom: 4px;
      font-size: 12px;
    }
  }

  .ant-select-single.ant-select-sm:not(.ant-select-customize-input)
    .ant-select-selector {
    padding: 3px 6px;
    height: unset;

    .ant-select-selection-item,
    .ant-select-selection-placeholder {
      font-size: 12px;
    }
  }
`

export const MiniTextInput = styled(Input).attrs({
  className: 'nodrag nowheel'
})`
  padding: 3px 6px;
  font-weight: 400;
  font-size: 14px;
  color: var(--text-dark-2);

  input {
    color: var(--text-dark-2);
  }
`

export const MiniTextArea = styled(Input.TextArea).attrs({
  autoSize: { minRows: 2, maxRows: 6 },
  className: 'nodrag nowheel'
})`
  padding: 3px 6px;
  font-weight: 400;
  font-size: 14px;
  color: var(--text-dark-2);
`
